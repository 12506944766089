const state = { 
    theme: 'light', // light or dark
    isLoading: false
};

const getters = { 
    getTheme: state => state.theme,
    getIsLoading: state => state.isLoading
};

const actions = {
    setTheme({commit}, theme) {
        commit("setTheme", theme);
    },
    setIsLoading({commit}, isLoading) {
        commit("setIsLoading", isLoading);
    }
};

const mutations = {
    setTheme(state, theme) {
        state.theme = theme;
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}