import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    ...require('@/locales/en/global.js'),
    ...require('@/locales/nl/global.js')
};

const i18n = new VueI18n({
    locale: 'nl', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;