const state = { 
    bridges: [
        { teken: "1", creator: "Wietse", address: "Pathoekeweg 82", infoPage: "Wietse", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "2", creator: "Smok", address: "Potentestraat 28", infoPage: "Smok", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "3", creator: "Djoels", address: "Werfstraat 51", infoPage: "Djoels", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "4", creator: "Alice Martha", address: "Veldmaarschalk Fochstraat 77", infoPage: "Alice Martha", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "5", creator: "Collie Theo", address: "Korendragerstraat 26", infoPage: "Collie Theo", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "6", creator: "Sam Massam", address: "Sint-Jozefstraat 2", infoPage: "Sam Massam", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "7", creator: "Naomi King", address: "Slachthuisstraat 31", infoPage: "Naomi King", location: { lat: 51.203464, lng: 3.206044 } },
    ]
};

const getters = { 
    getBridges3: state => state.bridges,
    getBridge3: state => value => {
        return state.bridges.find(brige => brige.teken === value)
    }
};

export default {
    state,
    getters
}