const state = { 
    bridges: [
        { teken: "1", creator: "Jamz", address: "Gistelse Steenweg 88", infoPage: "Jamz", location: { lat: 51.203464, lng: 3.206044 } },
        { teken: "2", creator: "Wietse", address: "Fonteinstraat 19", infoPage: "Wietse", location: { lat: 51.202358, lng: 3.214802 }, imageIndex: 6 },
        { teken: "3", creator: "Gijs Vanhee", address: "Peter Benoitlaan 1", infoPage: "Gijs Vanhee", location: { lat: 51.210131, lng: 3.211539 }, imageIndex: 1 },
        { teken: "4", creator: "Wietse", address: "Ijzerstraat 1", infoPage: "Wietse", location: { lat: 51.221102, lng: 3.221612 }, imageIndex: 7 },
        { teken: "5", creator: "Kymo One", address: "Prins Leopoldstraat 64", infoPage: "Kymo One", location: { lat: 51.211205, lng: 3.248328 }, imageIndex: 1 },
        { teken: "6", creator: "Bisser", address: "Generaal Lemanlaan 84", infoPage: "Bisser", location: { lat: 51.202009, lng: 3.238536 }, imageIndex: 1 },
        { teken: "7", creator: "Kitsune Jolene", address: "Hooistraat 46", infoPage: "Kitsune", location: { lat: 51.208250, lng: 3.236431 }, imageIndex: 1 },
        { teken: "8", creator: "Stan Slabbinck", address: "Rodestraat 53", infoPage: "Stan Slabbinck", location: { lat: 51.211433, lng: 3.235713 } },
        { teken: "9", creator: "Wietse", address: "Predikherenrei 1", infoPage: "Wietse", location: { lat: 51.209340, lng: 3.232135 }, imageIndex: 8 },
    ]
};

const getters = { 
    getBridges: state => state.bridges,
    getBridge: state => value => {
        return state.bridges.find(brige => brige.teken === value)
    }
};

export default {
    state,
    getters
}