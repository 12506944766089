import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

import i18n from './plugins/i18n'
i18n.locale = VueCookie.get("locale") || "nl"

import Default from "./layouts/Default.vue"
Vue.component("default-layout", Default)

Vue.config.productionTip = false

let app = ''

if (!app) {
  app = new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}