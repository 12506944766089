<template>
  <nav v-bind:class="{header: true, scrolled: scrolled, blur: isSafari, expanded: expandedNav}">
    <div class="top">
      <a v-bind:class="{'is-active': expandedNav, 'menu-button': true}" id="menuButton" @click="toggleNav"><span class="burger-icon"></span></a>

      <img src="@/assets/images/logo-white-border.png" @click="redirectTo('')" class="logo"/>
    </div>

    <div class="nav">
      <a @click="redirectTo('')" v-bind:class="{active: $route.name == 'home'}">{{ $t('header.home') }}</a>
      <a @click="redirectTo('map')" v-bind:class="{active: $route.name == 'seabridges-map'}">{{ $t('header.map') }}</a>
      <a @click="redirectTo('legende')" v-bind:class="{active: $route.name == 'legend'}">{{ $t('header.legend') }}</a>
      <a @click="redirectTo('partners')" v-bind:class="{active: $route.name == 'partners'}">{{ $t('header.partners') }}</a>

      <a @click="redirectTo('archief')" v-bind:class="{active: $route.name == 'bridges-map'}" style="font-size: 22px;">{{ $t('header.archive') }}</a>


      <div style="display: flex;">
        <a @click="changeLocale('en')" class="languageLink" v-if="$i18n.locale != 'en'" style="font-size: 22px;">ENGLISH</a>
        <a @click="changeLocale('nl')" class="languageLink" v-if="$i18n.locale != 'nl'" style="font-size: 22px;">NEDERLANDS</a>
      </div>
    </div>
  </nav>
</template>

<script>
  import i18n from '../../plugins/i18n';

  export default {
    name: 'MobileHeader',
    data() {
      return {
        scrolled: false,
        expandedNav: false
      }
    },
    computed: {
      isSafari() {
        return true;
        //return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
      }
    },
    methods: {
      onScroll() {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 0) {
          return
        }
        // Here we determine whether we need to show or hide the navbar
        this.scrolled = currentScrollPosition > 100
      },
      toggleNav() {
        this.expandedNav = !this.expandedNav;
      },
      changeLocale: function(locale) {
        i18n.locale = locale;
        this.$cookie.set('locale', locale, { expires: '1Y' });
        this.expandedNav = false;
      },
      redirectTo(page) {
        this.expandedNav = false;
        this.$router.push('/' + page);
      }
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll);
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";

  .header {
    height: 70px;
    transition: all .4s ease-in-out;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100000;
    background: $blue; // bridges
    // border-bottom: 1px solid rgb(0, 15, 78);
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .top {
      width: 100%;
      height: 70px;
      max-width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;
    }

    &.scrolled {
      box-shadow: 0 0 10px rgba(0,0,0,.05);
    }

    &.blur {
      background-color: transparent;

      &:before {
        backdrop-filter: saturate(180%) blur(20px);
        background-color: transparentize($blue, 0.12);
      }
    }

    &.expanded {
      height: 100vh;

      .nav {
        opacity: 1;
      }
    }
  }

  .logo {
    width: 40%;
    height: 100%;
    max-width: 44px;
    max-height: 70%;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    object-fit: contain;
    align-self: center;
    cursor: pointer;
  }

  .menu-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 100%;
    margin-left: 3%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    cursor: pointer;
  }

    .burger-icon {
      position: relative;
      user-select: none;
    }
    .burger-icon, .burger-icon::before, .burger-icon::after {
      display: block;
      width: 20px;
      height: 3px;
      background-color: white;
      outline: 1px solid transparent;
      transition-property: background-color, transform;
      transition-duration: 0.3s;
    }
    .burger-icon::before, .burger-icon::after {
      position: absolute;
      content: "";
    }
    .burger-icon::before {
      top: -6px;
    }
    .burger-icon::after {
      top: 6px;
    }
    .menu-button.is-active .burger-icon {
      background-color: transparent;
    }
    .menu-button.is-active .burger-icon::before {
      transform: translateY(6px) rotate(45deg);
    }
    .menu-button.is-active .burger-icon::after {
      transform: translateY(-6px) rotate(-45deg);
    }


  .nav {
    display: flex;
    opacity: 0;
    transition-duration: .4s;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    a {
      color: white;
      font-family: "DIN-Condensed-Bold", sans-serif;
      font-size: 35px;
      cursor: pointer;
      padding: 10px 0;

      &.active {
        color: $red;
      }

      &.languageLink {
        color: $red;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
</style>