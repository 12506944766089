import Vuex from 'vuex';
import Vue from 'vue';
import website from './modules/website';
import muren from './modules/muren';
import bridges from './modules/bridges';
import seabridges from './modules/seabridges';
import bridges3 from './modules/bridges3';
import info from './modules/info';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        website,
        muren,
        bridges,
        seabridges,
        bridges3,
        info
    }
});