const state = { 
    info: [
        { images: ["wietse.png", "a.jpg", "e.jpg", "f.jpg", "g.jpg", "n.jpg", "2.jpg", "4.jpg", "9.jpg"], name: "Wietse", insta: "wietseart",
            description: {
                nl: `De Brugse kunstenaar Wietse is de oprichter van dit festival. Zijn stijl is iets tussen 
                    cartoon en een realistisch standpunt. Hij creëert contrast in zijn werk door 
                    zwart-wit of door verf in kleur. 
                    Zijn inspiratie is gebaseerd op 3 dingen: geschiedenis (omdat de geschiedenis zich 
                    op een rare manier herhaalt), natuur en de belangrijkste invloed: reizen. Al deze 
                    dingen komen terug in zijn oeuvre. De schilderijen en tekeningen zijn gebaseerd op 
                    ervaringen in zijn persoonlijke leven of op reizen. Je kunt zijn kunstwerken zien 
                    in België, Europa en wereldwijd: van Vietnam tot Brazilië, van Argentinië tot 
                    Portugal. Alles is een canvas voor de kunstenaar.`,
                en: `The Bruges based artist Wietse is 
                    the founder of this festival. His style is something between cartoons and a realistic 
                    point of view. He puts contrast in his work by using black and white contrast or by 
                    colour paint. On his walls you will see the typical ‘Wietse’ style and see the 
                    evolution of his work. His inspiration is based on 3 things: history (because 
                    history repeats itself in a weird way), Nature and the most important one, namely 
                    travel. All these things are seen in his oeuvre. The paintings and drawings are 
                    based on experiences in his personal life or on many of his trips. You can see 
                    his artwork in Belgium, Europe and world-wide: from Vietnam to Brasil, from 
                    Argentina to Portugal. Everything is a canvas for 
                    the artist.`
            }
        },
        { images: ["bisser.png", "6-licht.jpg", "6-donker.jpg"], name: "Bisser", insta: "bisserbisser",
            description: {
                nl: `Bisser is een kunstenaar uit Leuven, België. Hij heeft een masterdiploma in 
                    animatiefilm en staat bekend om zijn grote muurschilderingen in zijn typisch 
                    herkenbare stijl. Hij schilderde voor verschillende festivals en in veel 
                    verschillende landen zoals; 
                    India, Mexico, de VS, het VK, Italië, Spanje, Frankrijk, Cyprus, Brazilië, ...`,
                en: `Bisser is an artist from Leuven, Belgium. He has a master degree in Animation Film 
                    and he is famous for his big murals in his typically recognizable style. He has 
                    painted for various festivals and in many different countries such as; 
                    India, Mexico, the USA, the UK, Italy, Spain, France, Cyprus, Brazil, ...`
            }
        },
        { images: ["kitsune.png", "7.jpg"], name: "Kitsune", insta:"kitsunejolene",
            description: {
                nl: `Kitsune Jolene (Jolien De Waele, 1992) is een Gentse street artist 
                    die de focus legt op realisme en intens kleurgebruik bij het 
                    afbeelden van portretten,fauna en flora. Ze is geïnspireerd door 
                    universele enviscerale emoties, natuur en mythologie.`,
                en: `Kitsune Jolene (Jolien De Waele, 1992) is a street artist 
                    from Ghent who focuses on realism and the intense use of colour when 
                    depicting portraits, fauna and flora. She is inspired by universal 
                    and visceral emotions, nature and mythology.`
            }
        },
        { images: ["jamz.png"], name: "Jamz", insta:"jamzjamezon",
            description: {
                nl: `Straatkunst is en blijft een belangrijke passie, samen met Stan Slabbinck startten 
                    ze al op jonge leeftijd met JASTA.  Ze brachten straatkunst met demonstraties en 
                    vormden graffiti om tot muurschilderijen.  Die liefde merk je ook in Jeremiah zijn 
                    beelden en creaties. Hij laat dit zien zowel op canvas, via samengestelde gerecycleerde 
                    materialen of mixed materials (spuitbus, verf, steen, balpen, ...) als op 
                    muren. Jeremiah's werk heeft vaak een futuristisch karakter met een primitieve 
                    aanpak. De overconsumptie, technologie, wetenschap versus natuur, maar ook de 
                    passie voor geschiedenis, ... zijn vaak terugkerende elementen. Ook werkt hij 
                    daarnaast als tekenaar, illustrator, ontwerper, creatief denker, en met veel 
                    interesse voor textiel: Musti, Ballonmedia. Zo start hij - samen met zijn liefde 
                    Sarah v.D - Sarazon-creative op, een multi-creatief lab. 
                    Ze maken ook films, storyboards, illustraties, muurschilderijen en interieur-decoraties 
                    en werken mee aan projecten zoals: Brugge Legendz, The young ones, Az-Brugge Pediatrie, ...`,
                en: `Street art is and remains an important passion, and together with Stan Slabbinck 
                    they start with JASTA already at young age. They brought street art with demonstrations 
                    and turned graffiti into wall paintings. You also notice this passion in 
                    Jeremiah's images and creations. He shows this both on walls and canvas, through 
                    composite recycled materials or mixed materials (aerosol, paint, stones, ballpoint 
                    pens, ...). Jeremiah's work often has a futuristic character with a primitive approach. 
                    The overconsumption, technology, science versus nature, but also the 
                    passion for history ... are often recurring elements. He also works as a draftsman 
                    illustrator, designer, creative thinker, with added interest for textile: Musti, 
                    Balloon media.  He started for example Sarazon-creative, which is a multi-creative 
                    lab that he created together with his girlfriend Sarah v.D.  They also make films, 
                    storyboards, illustrations, wall paintings and interior decorations and cooperate in 
                    projects such as: Brugge Legendz, The young ones, Az-Brugge Pediatrie, ...`
            }
        },
        { images: ["kymo-one.png", "5.jpg"], name: "Kymo One", insta:"kymo_one",
            description: {
                nl: `De drijvende kracht van KYMO ONE is het maken van muurschilderingen 
                    in de openbare ruimte om het werk op deze manier toegankelijk te maken 
                    voor een zo breed mogelijk publiek. Dit garandeert een interactie tussen 
                    een toevallig of een doelbewust publiek, alsook sluit het discriminatie uit wat 
                    betreft wie het werk kan zien. Typerend voor KYMO ONE is het gebruik 
                    van figuratieve elementen uit de kunstgeschiedenis, in combinatie met 
                    grafische en illustratieve elementen. Hiermee wordt getracht in dialoog te 
                    gaan met deze werken, alsook een begrip te krijgen van de sterkte die 
                    deze werken bevatten.`,
                en: `The driving force of KYMO ONE is making murals in public 
                    space in order to make the work accessible to the widest audience possible. This 
                    guarantees interaction between an accidental or conscious audience, as well as 
                    excluding any discrimination about who can see the work.
                    Typical for KYMO ONE is the use of figurative elements from art 
                    history, in combination with graphic and illustrative elements. 
                    This is an attempt to enter into a dialogue with these works, as 
                    well as to gain an understanding of the strength that these works contain.`
            }
        },
        { images: ["slabbinck.png"], name: "Stan Slabbinck", insta:"stanslabbinck",
            description: {
                nl: `STS Jasta, Stan Slabbinck komt uit een artistieke 
                    familie en is begonnen met zijn artistieke carrière 
                    als graffitikunstenaar eind jaren 80.  Punk, hiphop 
                    en reggae-muziek waren de eerste invloeden. Na 
                    studies aan de Academie van Brugge, het Sint-Lucas Gent en het Kask (Gentse Academie), bleef hij mixed media 
                    bestuderen, naast sculpturen en muurschilderingen. Naast het feit dat hij een vrije kunstenaar is, werkt hij 
                    ook voor themaparken en festivals in heel Europa.  STS haalt zijn inspiratie uit zowat alles, vaak met een 
                    sociaal-kritische noot, altijd in ontwikkeling en het thema natuur versus mens wordt vaak aangesproken, met 
                    als belangrijkste boodschap: <br>
                    "respecteer de natuur".`,
                en: `STS Jasta, Stan Slabbinck comes from an artistic 
                    family and started his artistic career as a graffiti 
                    artist in the late 80s. Punk, hiphop and reggae-music were 
                    his first influences. After studying at 
                    the Academy of Bruges, Sint-Lucas Ghent and Kask 
                    (Academy of Ghent), he continued to study the 
                    creation of mixed media, as well as sculptures and 
                    wall paintings. Next to being a free artist, he also 
                    works for themed parks and festivals all over 
                    Europe. STS gets his inspiration from just about 
                    everything, he is often critical towards society, 
                    and he is always evolving. The theme of nature 
                    versus people is often addressed, with the most 
                    important message being: <br>
                    "respect nature".`
            }
        },
        { images: ["gijs.png", "3.jpg"], name: "Gijs Vanhee", insta:"gijsvanhee",
            description: {
                nl: `Gijs is in 2006 afgestudeerd als productontwikkelaar,
                    maar al snel werd duidelijk dat hij andere wegen zou
                    bewandelen. Zijn eerste passie, tekenen, en ook het
                    creëren, is al jaren de rode draad bij het ontwerpen.
                    Verhalende tekeningenwaarin vogels een hoofdrol
                    spelen, surfen en het zoeken naar vrijheid vormen
                    de rode draad in zijn werk. Soms met inkt op papier,
                    soms met verf op muren. Vaak is zijn werk auto-
                    biografisch of vertelt het een anekdote over de plaats.
                    Zijn tekeningen hebben een zeer herkenbare stijl,
                    vaak in zwart-wit of met een beperkt kleurgebruik.
                    Bij de muurschilderingen spelen de aanwezige
                    elementen of texturen een belangrijke rol in het werk.`,
                en: `Gijs Vanhee (1982). Gijs graduated in 2006 as a product developer, but it soon became clear to him that
                    he would take another road in life. His first passions, drawing and creating, have been his common
                    threads for years and therefore have quickly taken the upper hand in designing.
                    Narrative drawings in which birds play a leading role, the act of surfing and the
                    search for freedom in general form the common thread throughout his work contents-wise.
                    Sometimes Gijs works with ink on paper, other times 
                    with paint on walls. Often his work is autobiographical
                    or it tells a story about the place itself.
                    His drawings have a very recognizable style, often
                    in black and white or with limited use of colour.
                    In his murals, the elements or texture that are 
                    present usually play an important role in the work.`
            }
        },
        { images: ["strook.jpg"], name: "Strook", insta:"strook_be",
            description: {
                nl: ``,
                en: ``
            }
        },


        // seabridges
        { images: ["nigel_leirens.jpg"], name: "Nigel Leirens", insta:"nigel_leirens",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["viktoir.jpg"], name: "Viktoir Viktoir", insta:"viktoir.viktoir",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["tuzq_one.jpg"], name: "Tuzq one", insta:"tuzq_one",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["sammyslabbinck.jpg"], name: "Sammy Slabbinck", insta:"sammyslabbinck",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["buethewarrior.jpg"], name: "Bue Thewarrior", insta:"buethewarrior",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["simonmannaerts.jpg"], name: "SIMO", insta:"simonmannaerts",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: ["friekeverle.jpg"], name: "Frieke Verle", insta:"friekeverle",
            description: {
                nl: ``,
                en: ``
            }
        },


        // bridges 3
        { images: [], name: "Smok", insta:"_smok_",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: [], name: "Djoels", insta:"djoels_graffiti_paint",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: [], name: "Alice Martha", insta:"alicemartha.am",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: [], name: "Collie Theo", insta:"collie_theo",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: [], name: "Sam Massam", insta:"sam_massam",
            description: {
                nl: ``,
                en: ``
            }
        },
        { images: [], name: "Naomi King", insta:"naomiking010",
            description: {
                nl: ``,
                en: ``
            }
        },
    ]
};

const getters = { 
    getInfo: state => value => {
        return state.info.find(kunstenaar => kunstenaar.name === value)
    }
};

export default {
    state,
    getters
}
