const state = { 
    bridges: [
        { teken: "1", creator: "Nigel Leirens", address: "Vismijnstraat 7", infoPage: "Nigel Leirens", location: { lat: 51.332318, lng: 3.202525 } },
        { teken: "2", creator: "Viktoir Viktoir", address: "Ploegstraat 26", infoPage: "Viktoir Viktoir", location: { lat: 51.330491, lng: 3.209412 } },
        { teken: "3", creator: "Tuzq one", address: "Noordzeestraat 4", infoPage: "Tuzq one", location: { lat: 51.327132, lng: 3.202683 } },
        { teken: "4", creator: "Wietse", address: "Lisseweegse Steenweg 64", infoPage: "Wietse", location: { lat: 51.312, lng: 3.197 } },
        { teken: "5", creator: "Sammy Slabbinck", address: "Zwankendammestraat 10", infoPage: "Sammy Slabbinck", location: { lat: 51.31, lng: 3.196 } },
        { teken: "6", creator: "Bue Thewarrior", address: "Zeebruggelaan 75", infoPage: "Bue Thewarrior", location: { lat: 51.29622, lng: 3.19164 } },
        { teken: "7", creator: "SIMO", address: "Zeebruggelaan 14", infoPage: "SIMO", location: { lat: 51.29404, lng: 3.19237 } },
        { teken: "8", creator: "Frieke Verlé", address: "Watergang 31", infoPage: "Frieke Verle", location: { lat: 51.272, lng: 3.22197 } }
    ]
};

const getters = { 
    getSeabridges: state => state.bridges,
    getSeabridge: state => value => {
        return state.bridges.find(brige => brige.teken === value)
    }
};

export default {
    state,
    getters
}