const state = { 
    muren: [
        { teken: "a", creator: "Wietse", address: "VTI Zandstraat", infoPage: "Wietse", location: { lat: 51.202472, lng: 3.195020 }, imageIndex: 1 },
        { teken: "b", creator: "Strook", address: "‘t Sandpoortje", infoPage: "Strook", location: { lat: 51.206057, lng: 3.217439 } },
        { teken: "c", creator: "Jamz", address: "Beursplein", infoPage: "Jamz", location: { lat: 51.204361, lng: 3.214384 } },
        { teken: "d", creator: "Stan Slabbinck", address: "Concertgebouw", infoPage: "Stan Slabbinck", location: { lat: 51.203900, lng: 3.218434 } },
        { teken: "e", creator: "Wietse", address: "Baron Ruzettelaan 260", infoPage: "Wietse", location: { lat: 51.1882, lng: 3.234135 }, imageIndex: 2 },
        { teken: "f", creator: "Wietse", address: "Vlamingdam 36", infoPage: "Wietse", location: { lat: 51.216897, lng: 3.220807 }, imageIndex: 3 },
        { teken: "g", creator: "Wietse", address: "Tittecastraat 132", infoPage: "Wietse", location: { lat: 51.189730, lng: 3.202686 }, imageIndex: 4 },
        { teken: "h", creator: "Entrepot", address: "Skatepark", location: { lat: 51.224937, lng: 3.225921 } },
        { teken: "i", creator: "Mattia Campo Sall'Orto", address: "ENTREPOT", location: { lat: 51.226338, lng: 3.225906 } },
        { teken: "j", creator: "Frank & Stan", address: "Bargeweg /brug", location: { lat: 51.198038, lng: 3.227440 } },
        { teken: "k", creator: "Stan Slabbinck", address: "Pannebekestraat 44", infoPage: "Stan Slabbinck", location: { lat: 51.226920, lng: 3.231456 } },
        { teken: "l", creator: "Stan Slabbinck", address: "Moerkerkse Steenweg 21", infoPage: "Stan Slabbinck", location: { lat: 51.212689, lng: 3.242687 } },
        { teken: "m", creator: "Fietsentunnel", address: "Unesco-rotonde", location: { lat: 51.200490, lng: 3.218181 } },
        { teken: "n", creator: "Wietse", address: "Pathoekeweg 54", infoPage: "Wietse", location: { lat: 51.228847, lng: 3.214508 }, imageIndex: 5 }
    ]
};

const getters = { 
    getMuren: state => state.muren,
    getMuur: state => value => {
        return state.muren.find(muur => muur.teken === value)
    }
};

export default {
    state,
    getters
}