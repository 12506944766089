<template>
  <div id="page-container">
    <Header ref='header' />
    
    <div id="content-wrap">
      <slot/>
    </div>
 </div>
</template>

<script>
import Header from '@/components/layout/Header';

export default {
  components: {
    Header
  }
}
</script>

<style lang="scss">
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";

  #page-container {
    position: relative;
    display: flex;
    min-height: 100%;
    background-color: $sand;
  }

  #content-wrap {
    flex: 1;
    padding-top: 70px;
    transition-duration: .3s;
  }
</style>
