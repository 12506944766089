import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta, {
  refreshOnceOnNavigation: true
});

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/map',
      redirect: "/map/bridges3"
    },
    {
      path: '/archief',
      name: 'archief',
      component: () => import('./views/Archive.vue')
    },
    {
      path: '/map/bridges3',
      name: 'bridges-3-map',
      component: () => import('./views/maps/Bridges3.vue')
    },
    {
      path: '/map/seabridges',
      name: 'seabridges-map',
      component: () => import('./views/maps/Seabridges.vue')
    },
    {
      path: '/map/bridges',
      name: 'bridges-map',
      component: () => import('./views/maps/Bridges.vue')
    },
    {
      path: '/info',
      name: 'info',
      component: () => import('./views/Info.vue')
    },
    {
      path: '/legende',
      name: 'legend',
      component: () => import('./views/Legend.vue')
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('./views/Partners.vue')
    },
    {
      path: '/voorwoord',
      name: 'voorwoord',
      component: () => import('./views/Voorwoord.vue')
    },
    /*{
      path: '/404',
      name: '404*',
      component: () => import('./views/404.vue'),
      meta: {
        gtm: '404'
      }
    }*/
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 300);
    });
  }
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;